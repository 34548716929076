// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white mt-5 text-center">
      <div className="container">
        <span>Single Sign On Booking App &copy; 2025</span>
      </div>
    </footer>
  );
};

export default Footer;
