/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Training.css'; // Import the CSS file

const Training = () => {

  return (
    <div className="training-container">
      {/* Introductory Text */}
      <div className="intro-text">
        <p>
          For more information about what SSO is as well as how to do things like Enrol, please view the Video below.
        </p>
        <p>
        <iframe title="trainingVid" style={{marginTop: 5 + 'em'}} width="1280" height="720" src='https://www.powtoon.com/embed/d5waPXLEl5V/' frameborder="0" allowfullscreen></iframe>
        </p>
      </div>

    </div>
  );
};

export default Training;
