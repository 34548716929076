/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './BookingForm.css'; // Import the CSS file
import hospitalLogo from '../connolly-logo.png';
import imprivataLogo from '../imprivata-logo.png';

const BookingForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL || 'https://server-ruddy-three.vercel.app';
  // const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';

  useEffect(() => {
    if (date && apiUrl) {
      const fetchTimeSlots = async () => {
        try {
          console.log("FETCHING TIMESLOTs");
          const response = await axios.get(`${apiUrl}/api/timeslots`, {
            params: { date }
          });
          setTimeSlots(response.data);
        } catch (error) {
          console.error('Error fetching time slots:', error);
          setError('Failed to load time slots. Please try again later.');
        }
      };

      fetchTimeSlots();
    }
  }, [date, apiUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const userResponse = await axios.post(`${apiUrl}/api/users`, { firstName, lastName, email });
      await axios.post(`${apiUrl}/api/bookings`, {
        user: userResponse.data._id,
        slot: selectedSlot
      });

      const slot = timeSlots.find(slot => slot._id === selectedSlot);

      navigate('/success', {
        state: {
          firstName,
          lastName,
          email,
          timeslot: slot ? slot.slotTime : null
        }
      });
    } catch (error) {
      console.error('Error occurred:', error);
      setError('Failed to complete the booking. Please try again later.');
    }
  };

  return (
    <div className="container">
      <div className="logo-container">
        <img
          src={hospitalLogo}
          alt="Banner"
          className="hospital-logo"
        />
        <img
          src={imprivataLogo}
          alt="Banner"
          className="imprivata-logo"
        />
      </div>
      <div className="intro-text">
        <p>
          Great news! Imprivata Single Sign-On is coming to Connolly Hospital! We will be running an Enrolment fair in order to get staff set up on the system.
          Please book your preferred time slot using the form below.
        </p>
      </div>

      <div className="booking-form-container">
        <form className="booking-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="HSE Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <select value={date} onChange={(e) => setDate(e.target.value)} required>
            <option value="">Select a date</option>
            <option value="2024-07-10">July 10, 2024 - GUH</option>
            <option value="2024-07-11">July 11, 2024 - GUH</option>
            <option value="2024-07-12">July 12, 2024 - Merlin Park</option>
          </select>
          <select value={selectedSlot} onChange={(e) => setSelectedSlot(e.target.value)} required>
            <option value="">Select a time slot</option>
            {timeSlots.map(slot => (
              <option key={slot._id} value={slot._id}>
                {new Date(slot.slotTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </option>
            ))}
          </select>
          <button type="submit">Book</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default BookingForm;
